<template>
  <div>
    <page-title></page-title>
    <!-- Table Container Card -->

    <b-card class="mt-1 position-relative">
      <b-button variant="success" class="float-right mb-1" v-b-modal.project_add_modal>新增项目</b-button>
      <b-modal id="project_add_modal"
               title="新增项目"
               cancel-title="取消"
               cancel-variant="outline-secondary"
               ok-title="确定"
               button-size="sm"
               size="lg"
               @ok.prevent="addProjectFn()"
      >
        <b-card>
          <p><strong>请事先检查核对以下前置步骤是否已经完成,前置步骤完成后才能成功创建新项目</strong></p>
          <b-list-group>
            <b-list-group-item>
              <b-checkbox v-model="project_add_info[0]">
                1.请在hpbc_admin数据库的xj_project表中添加新的项目数据库连接参数，并记下新项目的id。
              </b-checkbox>

            </b-list-group-item>
            <b-list-group-item>
              <b-checkbox v-model="project_add_info[1]">
                2.请按照上一步中的新项目数据库连接参数确认新数据库已创建。并导入标准的新项目初始化sql文件。
              </b-checkbox>

            </b-list-group-item>
            <b-list-group-item>
              <b-checkbox v-model="project_add_info[2]">
                3.请确认新项目数据库中至少存在以下4张数据表：cde_building、cde_building_type、cde_energytype、cde_posi_info
              </b-checkbox>

            </b-list-group-item>
            <b-list-group-item>
              <b-checkbox v-model="project_add_info[3]">
                4.请确认cde_building、cde_posi_info两张表中已填入新项目的基础信息，我们会根据这些信息在平台上创建新项目。
              </b-checkbox>
            </b-list-group-item>
          </b-list-group>

          <b-form-group class="mt-2" label="请填写项目id" :disabled="checkProjectAddInfo()">
            <b-form-input placeholder="请先确认以上前置步骤已完成" type="number"
                          v-model="project_add_id"></b-form-input>
          </b-form-group>

          <p v-show="project_add_error" class="text-danger">错误信息：<strong>{{ project_add_error }}</strong></p>
        </b-card>
      </b-modal>
      <b-table ref="refProjectListTable" :empty-text="$t('No Data')" :fields="$t('tableColumnsProject')" :items="data"
               class="position-relative" primary-key="id" responsive show-empty small>
        <template v-slot:table-colgroup="scope">
          <col
              v-for="field in scope.fields"
              :key="field.key"
              :class="field.key === 'description' ? 'ell' : ''"
              :style="{
							width: field.key === 'description' ? '40%' : '' || field.key === 'order' ? '6%' : '' || field.key === 'projectName' ? '20%' : '',
						}"
          />
        </template>
        <!-- Column: Project-Avater -->
        <template #cell(order)="data">
          <div class="text-center">
            <span>{{ data.index + 1 }}</span>
          </div>
        </template>
        <template #cell(projectAvater)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img :src="data.item.images[0]" alt="" style="width: 100px"/>
            </template>
          </b-media>
        </template>

        <!-- Column:project_name -->
        <template #cell(projectName)="data">
          <span class="align-text-top text-capitalize">{{ data.item.project_name }}</span>
        </template>

        <!-- Column: description -->
        <template #cell(description)="data">
					<span
          ><small>{{ data.item.description }}</small></span
          >
        </template>
        <!-- location -->
        <template #cell(location)="data">
          <div class="text-nowrap">
            <b-row>
              <b-col cols="12" md="12">
                经度：
                <span>{{ data.item.lng }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                纬度：
                <span>{{ data.item.lat }}</span>
              </b-col>
            </b-row>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="align-middle text-center">
            <b-button
                :to="{
								name: 'admin-projects-edit',
								params: {
									title: 'Project Edit',
									id: data.item.project_id,
									levels: [
										{ title: 'System Manage' },
										{
											title: 'Project List',
											name: 'admin-projects-list',
										},
									],
								},
							}"
                class="btn-icon"
                size="sm"
                variant="warning"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>

            <b-button
                class="btn-icon ml-50"
                size="sm"
                variant="danger"
                @click="deleteProjectFn(data.item.project_id)"
            >
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import {getCurrentInstance, ref} from "@vue/composition-api";
import {avatarText} from "@/@core/utils/filter";
import useProjectsList from "./useProjectsList";
import PageTitle from "@/views/layout/PageTitle";
import {showToast} from "@/libs/utils/showToast";

import {showConfirm} from "@/libs/utils/showConfirm";

export default {
  components: {
    PageTitle,
    vSelect,
  },
  data() {
    return {
      data: [],
      project_add_info: [false, false, false, false],
      project_add_id: null,
      project_add_error: ''
    };
  },
  setup() {
    let instance = getCurrentInstance().proxy;

    const searchProject = (value) => {
      store
          .dispatch("projects/fetchProjects", {
            project_name: value,
            pagesize: 10000,
          })
          .then((response) => {
            console.log(response.data);
            instance.data = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    };
    const isAddNewProjectSidebarActive = ref(false);
    const {refProjectListTable, refetchData} = useProjectsList();

    const checkProjectAddInfo = () => {
      return !(instance.project_add_info[0] && instance.project_add_info[1] && instance.project_add_info[2] && instance.project_add_info[3]);
    }

    const addProjectFn = () => {
      if (instance.checkProjectAddInfo()) {
        showToast(null, '提示', '请先确认前置步骤已完成')
        return
      }
      if (!instance.project_add_id) {
        showToast(null, '提示', '请正确填写项目id')
        return
      }
      if (instance.project_add_id > 999) {
        showToast(null, '提示', '请正确填写项目id')
        return
      }

      store.dispatch('projects/addProject', {projects: [instance.project_add_id]})
          .then(response => {
            showToast(null, '添加成功', '添加项目成功')
            //刷新页面
            refetchData()
          })
          .catch(error => {
            const response = error.response;
            let errorText = response.data.message || response.data.data.message;
            if (errorText.indexOf("<h1>") > -1) {
              errorText = errorText.match(/<h1>(\S*)<\/h1>/)[1];
            }
            instance.project_add_error = errorText;
            showToast(error)
          })
    }

    const deleteProjectFn = (project_id) => {
      showConfirm('你确定要删除该项目吗？', () => {
        //获取当前时间戳
        store.dispatch('projects/deleteProject', project_id)
            .then((response) => {
              showToast(null, '成功', '删除项目成功')
              //刷新页面
              refetchData()
            })
            .catch((error) => {
              showToast(error)
            })

      })
    }

    return {
      // Sidebar
      isAddNewProjectSidebarActive,
      refProjectListTable,
      refetchData,

      // Filter
      avatarText,

      checkProjectAddInfo,
      addProjectFn,
      deleteProjectFn
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
//noinspection CssUnknownTarget
@import "@core/scss/vue/libs/vue-select.scss";

.table th:nth-last-child(1) {
  text-align: center;
}

.table {
  table-layout: fixed;
}

.table td:nth-child(4) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // display: -webkit-box;
  //  text-overflow:ellipsis;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}
</style>
